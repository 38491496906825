import ResetPassModule from "../../module/resetPassModule/resetPassModule";

function ResetPass() {
  return (
    <div className="ResetPass">
      <ResetPassModule/>
    </div>
  );
}

export default ResetPass;