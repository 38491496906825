import HeaderModule from "../../../module/headerModule/headerModule";
import Titlepage from "../../../UI/Title/title";
import LeftPanelModule from "../../../module/leftPanelModule/leftPanelModule";
import AddBarModule from "../../../module/addBarModule/addBarModule";

function Add() {

  return (
    <div className="Add">
     <HeaderModule/>
     <div className="flex-main">
      <LeftPanelModule id={'link_6'}/>
      <div id="mainRightPanel" className="main-right-panel">
        <Titlepage name={'Замена/добавление продукта'}/>
        <AddBarModule/>
      </div>
     </div>
    </div>
  );
}

export default Add;