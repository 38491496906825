import style from './css/dynamicsBarModule.module.css'
import Input from '../../UI/input/input'
import Button from '../../UI/button/button'
import TitleBlock from '../../UI/titleBlock/titleBlock';
import Combobox from '../../UI/combobox/combobox';
import TextArea from '../../UI/textarea/textarea';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build//vfs_fonts'
import LineGrafic from '../../UI/lineGrafic/lineGrafic';
import Tooltip from '../../UI/tooltip/tooltip';
import ErrorPanel from '../errorPanel/errorPanel'
import { useEffect, useRef, useState } from 'react';
import { useScreenshot } from 'use-react-screenshot';
import axios from 'axios';
import { createPDF } from '../../function/pdfCreate/pdfCreate';
import LoaderModule from '../loaderModule/loaderModule';

let file = []


function DynamicsBarModule() {  
  const data = [];
  let timeAll = new Date().getTime()

  for (let index = 0; index < 30; index++) {
    data.push({year: new Date(timeAll).toLocaleDateString(), count: 0 })
  }
  
  let list = [{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''}]

  let email = localStorage.getItem('email')

  const [state2, setState2] = useState(null)
  const [errorState, setErrorState] = useState(false)
  const [state4, setState4] = useState(list)
  const [state6, setState6] = useState(list)
  const [state3, setState3] = useState(null)
  const [state5, setState5] = useState(null) 
  
  useEffect(()=>{
    axios.get(`http://5.188.159.168:8989/api/scores?email=${email}`)
    .catch((error) => console.log(error))
    .then(res => {
      setState2(res.data);
    })
  },[])

  
  const ref = useRef(null)
  const [image, takeScreenshot] = useScreenshot({
    type:'image/png',
    quality: 1.0
  })

  const createPDFFile = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(createPDF(file)).download('name.pdf');
  }

  const getMasiv = (image) => {
    let fiel = []
    file.map(item=>{
      fiel.push(item)
    })
    fiel.push({text: document.getElementById('textarea').value, fontSize: 16,})
    fiel.push({image:image, width: 400})
    file = fiel
  }

  const getimg = () => takeScreenshot(ref.current).then(getMasiv)

  useEffect(()=>{
    if (state6.length !== list.length) {
      let chart = []
      let element = []
      for (let index = 0; index < state6.length; index++) {
        for (let index2 = 0; index2 < state6[index].chart.data.length; index2++) {
          element.push({year: new Date(state6[index].chart.date[index2]*1000).toLocaleDateString(), count: state6[index].chart.data[index2]})
        }
        chart.push(element)
        element = []
      }
      setState5(chart)
      setState3(chart[0])
    }
  },[state6])

  const startAnalis = () => {
    let timeTo = Date.parse(document.getElementById('timeTo').value)
    let timeFrom = Date.parse(document.getElementById('timeFrom').value)
    let options = document.getElementById('seledes')
    let id = options.selectedIndex + 1
    if (timeTo|| 0 !== 0 && timeFrom|| 0 !== 0) {
      axios.get(`http://5.188.159.168:8989/api/dataDynamics?timeFrom=${timeFrom/1000}&timeTo=${timeTo/1000}&id=${id}&email=${email}`)
      .catch((error) => console.log(error))
      .then(res => {
        setState4(res.data)
        setState6(res.data[0].table)
      })
    }else{
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000);
    }
  }

  const specChange = () => {
    let options = document.getElementById('seledes2')
    let id = options.selectedIndex 
    setState6(state4[id].table)
  }

  const specChange2 = () => {
    let options = document.getElementById('seledes3')
    let id = options.selectedIndex 
    setState3(state5[id])
  }
  
  return (
    <div style={{backgroundColor: '#222831'}} ref={ref}>
      {state2? 
        <div className={style.dynamicsBarModule}>
          <TitleBlock name={'Настройка параметров анализа'}/>
          <div className={style.dynamicsBarModule_filterBlock + ' ' + style.dynamicsBarModule_Block}>
            <div className={style.dynamicsBarModule_inner}>
              <div className={style.dynamicsBarModule_filters}>
                <Combobox id={'seledes'} list={state2} name={'Выбрать стратегию'}/>
                <div className={style.dynamicsBarModule_date}>
                  <Input id={'timeFrom'} name={'Выбрать начальную дату  '} type={'date'}/>
                  <Input id={'timeTo'} name={'Выбрать конечную дату  '} type={'date'}/>
                </div>
              </div>
              <Button onClick={startAnalis} name={'Начать анализ'}/>
            </div>
          </div>
          <div className={style.dynamicsBarModule_infoBlocks}>
            <div className={style.dynamicsBarModule_infoBlocks_inner}>
              <TitleBlock name={'Коментирование/Сохранение результата'}/>
              <div className={style.dynamicsBarModule_infoBlock + ' ' + style.dynamicsBarModule_Block}>
                <Combobox id={'seledes2'} onChange={specChange} list={state4} name={'Выбор группы показателей'}/>
                <Combobox id={'seledes3'} onChange={specChange2} list={state6} name={'Выбор визуализируемых показателей счёта'}/>
                <TextArea id={'textarea'} palceholder={'Поле для ввода пометок к результатам'}/>
                <div className={style.button_inner}>
                  <Tooltip name={'Нажав кнопку в отчёт вы начнёте наполнения файла с отчётом, после каждного нажатия в файле будет появляться новая информация в файле к дополнения к прошлой'}/>
                  <Button onClick={getimg} name={'В отчёт'}/>
                </div>
                <div className={style.button_inner}>
                  <Tooltip name={'Нажав кнопку сгенерировать отчёт вы окончите наполнение файлаб и файл будет создаватьс заного'}/>
                  <Button onClick={createPDFFile} name={'Сгенерировать отчёт'}/>
                </div>
              </div>
            </div>
            <div className={style.dynamicsBarModule_infoBlocks_inner}>
              <TitleBlock name={'Визуализация результата'}/>
              <div className={style.dynamicsBarModule_infoBlock + ' ' + style.dynamicsBarModule_Block + ' ' + style.dynamicsBarModule_infoBlock_grafics}>
                <LineGrafic data={state3?state3:data}/>
              </div>
            </div>
          </div>
        </div>:
    <LoaderModule/>
    }{errorState? 
      <ErrorPanel text={'Задан некорректный интервал времени'}/>:
      <div className=""></div>
    }
    </div>
  );
}

export default DynamicsBarModule;