import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  info: [
    {name: '',balance:0,annualGrowth: 0,annualToday:0}
  ]
}


export const infoItem = createSlice({
  name: 'infoItem',
  initialState,
  reducers: {
    setInfoItem: (state, action) => {
      state.info = action.payload
    },
  }
})

export const { setInfoItem } = infoItem.actions
export default infoItem.reducer