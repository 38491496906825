import style from './css/errorPanel.module.css'
import img from './image/emergency_home_FILL0_wght400_GRAD0_opsz24.svg'

function ErrorPanel({text}) {

  return (
    <div className={style.ErrorPanel}>
      <div className={style.ErrorPanel_Content}>
        <img src={img} alt="" />
        <div>{text}</div>
      </div>
    </div>
  );
}

export default ErrorPanel;