import { Link, useNavigate } from 'react-router-dom';
import style from './css/resetPassModule.module.css'
import Title from './components/UI/Title/title';
import Button from '../../UI/button/button'
import Input from '../../UI/input/input'
import axios from 'axios';

function ResetPassModule() {
  const route = useNavigate()

  const Gologin = () =>{
    let email = document.getElementById('email')
    let newPass = document.getElementById('newPass')
    let newPass2 = document.getElementById('newPass2')
    axios.post('http://5.188.159.168:8904/api/resetpass', {
        email: email.value,
        codeemail: '',
        codenumber: '',
        newpass: newPass.value,
        newpass2: newPass2.value,
      })
      .catch((error) => console.log(error))
      .then(res => {
        route('/login')
      })
  }


  return (
    <div className={style.ResetPassModule}>
      <div className={style.ResetPassModule_block}>
        <div className={style.ResetPassModule_inner}>
          <Title name={'Востановление пароля'}/>
          <Input id={'email'} name={'Почта или номер'} type={'text'}/>
          <Button onClick={Gologin} name={'Получить код'}/>
          <br/>
          <Input name={'Код'} type={'text'}/>
          <Input id={'newPass'} name={'Новый пароль'} type={'text'}/>
          <Input id={'newPass2'} name={'Повторно пароль'} type={'text'}/>
          <br/>
          <Link to={'/login'} className={style.ResetPassModule_link} >Вернуться назад</Link>
          <Button onClick={Gologin} name={'Сохранить пароль'}/>
        </div>
      </div>
    </div>
  );
}

export default ResetPassModule;