import HeaderModule from "../../../module/headerModule/headerModule";
import Titlepage from "../../../UI/Title/title";
import LeftPanelModule from "../../../module/leftPanelModule/leftPanelModule";
import StatiscsBarModule from "../../../module/statiscsBarModule/statiscsBarModule";

function Statistic() {

  return (
    <div className="Statistic">
     <HeaderModule/>
     <div className="flex-main">
      <LeftPanelModule id={'link_2'}/>
      <div id="mainRightPanel" className="main-right-panel">
        <Titlepage name={'Статистика операционных показателей'}/>
        <StatiscsBarModule/>
      </div>
     </div>
    </div>
  );
}

export default Statistic;