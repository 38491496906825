import Button from '../../UI/button/button';
import CheckBox from '../../UI/checkbox/checkbox';
import Combobox from '../../UI/combobox/combobox';
import Input from '../../UI/input/input';
import TextArea from '../../UI/textarea/textarea';
import style from './css/paymentBarModule.module.css'

function PaymentBarModule() {
  let list = ['счёт 1','счёт 2','счёт 3','счёт 4','счёт 5','счёт 6','счёт 7','счёт 8',]

  return (
    <div className={style.paymentBarModule}>
      <div className={style.paymentBarModule_Block}>
        <div className={style.checkBoxs}>
          <CheckBox name={'Снятие средств со счёта'}/>
          <CheckBox name={'Добавление средств на счёт'}/>
          <CheckBox name={'Закрытие счёта'}/>
        </div>
        <Combobox list={list} name={'Выбор продукта(торговой стратегии)'}/>
        <TextArea palceholder={'Текстовое поле описания торговой стратегии'}/>
        <Input name={'Задание велечины счёта'} type={'text'}/>
        <Input name={'Назначение времени открытия счёта'} type={'date'}/>
        <Button name={'Заказать встречу с менеджером'}/>
      </div>
    </div>
  );
}

export default PaymentBarModule;