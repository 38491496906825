import style from './css/checkbox.module.css'

function CheckBox({name}) {
  return (
    <div className={style.content}>
      <div className={style.title}>{name}</div>
      <label className={style.checkBox}>
        <input id="ch1" type="checkbox"/>
        <div className={style.transition}></div>
      </label>
    </div>
  );
}

export default CheckBox;