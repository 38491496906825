import style from './css/titlel.module.css'

function Title({name}) {
  return (
    <div className={style.title}>
      {name}
    </div>
  );
}

export default Title;