import { useNavigate } from "react-router-dom";
import Page from "./page/page";
import { useEffect, useState } from "react";
import LoaderModule from "./module/loaderModule/loaderModule";

function App() {  
  const [state, setState] = useState(1)

  const route = useNavigate()
  const redirection = () =>{
    if (localStorage.getItem('login') !== 'admin'){
      route('./login')
    }
  }

  useEffect(()=>{ 
    redirection()
    setTimeout(() => {
      setState(0)
    }, 1000);
  },[])
  return (
    <div className="App">
      {state === 0? <Page/>: <LoaderModule/>}
    </div>
  );
}

export default App;
