import { Link, useNavigate } from 'react-router-dom';
import Button from '../../UI/button/button'
import Input from '../../UI/input/input'
import style from './css/loginModule.module.css'
import Title from './components/UI/Title/title';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ErrorPanel from '../errorPanel/errorPanel';

function LoginModul() {
  const route = useNavigate()

  const [errorState, setErrorState] = useState(false)
  const [text, setText] = useState('Не введен логин или пароль')



  const login = () =>{
    let login = document.getElementById('login')
    let pass = document.getElementById('pass')

    if (login.value !== '' && pass.value !== '') {
      axios.post('http://5.188.159.168:8904/api/login', {
        login: login.value,
        pass: pass.value,
      })
      .catch((error) => console.log(error))
      .then(res => {
        if (res.data !== 400) {
          localStorage.setItem('email', res.data)
          route('/codeEmail')
        } else {
          setText('Неверно введен логин или пароль')
          setErrorState(true)
          setTimeout(() => {
            setErrorState(false)
          }, 5000);
        }
      })
    } else {
      setText('Не введен логин или пароль')
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000);
    }
  }

  const redirection = () =>{
    if (localStorage.getItem('login') === 'admin'){
      route('/')
    }
  }

  useEffect(()=>{ 
    redirection()
  },[])

  return (
    <div className={style.LoginModul}>
      <div className={style.LoginModul_block}>
        <div className={style.LoginModul_inner}>
          <Title name={'Вход'}/>
          <Input id={'login'} name={'Логин'} type={'text'}/>
          <Input id={'pass'} name={'Пароль'} type={'password'}/>
          <br/>
          <div className={style.LoginModul_link_block}>
            <Link to={'/resetpass'} className={style.LoginModul_link} >Забыли пароль</Link>
            <Link to={'/register'} className={style.LoginModul_link} >Зарегестрирвоаться</Link>
          </div>
          <Button onClick={login} name={'Войти'}/>
        </div>
      </div>
      {errorState? 
      <ErrorPanel text={text}/>:
      <div className=""></div>
      }
    </div>
  );
}

export default LoginModul;