import HeaderModule from "../../../module/headerModule/headerModule";
import Titlepage from "../../../UI/Title/title";
import LeftPanelModule from "../../../module/leftPanelModule/leftPanelModule";
import TransactionsBarModule from "../../../module/transactionsBarModule/transactionsBarModule";

function Tranz() {

  return (
    <div className="Tranz">
     <HeaderModule/>
     <div className="flex-main">
      <LeftPanelModule id={'link_4'}/>
      <div id="mainRightPanel" className="main-right-panel">
        <Titlepage name={'Отчётность по торговым транзакциям'}/>
        <TransactionsBarModule/>
      </div>
     </div>
    </div>
  );
}

export default Tranz;