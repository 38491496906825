import { useEffect } from 'react';
import Combobox2 from './components/combobox/combobox';
import ItemInfo from './components/itemInfo/itemInfo';
import style from './css/headerModule.module.css'
import img from './img/cross-svgrepo-com.svg'
import info from './img/info-svgrepo-com.svg'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoItem } from '../../features/infoItem/infoItem';
import { setId } from '../../features/id/id';

function HeaderModule() {  

  let email = localStorage.getItem('email')
  const dispach = useDispatch()


  const state2 = useSelector((state) => state.item.info)
  const state = useSelector((state) => state.id.info)

  const openClouseRightPanel = () => {
    let block = document.getElementById('HeaderModule_info')
    if (block.classList.contains(style.HeaderModule_info_active)) {
      block.classList.remove(style.HeaderModule_info_active)
    } else {
      block.classList.add(style.HeaderModule_info_active)
    }
  }

  useEffect(()=>{
    if (state2[0].name === '') {
      axios.get(`http://5.188.159.168:8989/api/scores?email=${email}`)
      .catch((error) => console.log(error))
      .then(res => { 
        dispach(setInfoItem(res.data))
      })
    }
    document.getElementById("combobox").selectedIndex = state;
  },[])

  const combobboxChenge = () => {
    let options = document.getElementById('combobox')
    let id = options.selectedIndex 
    dispach(setId(id))
  }

  let list = ['Стратегия максимизации доходности при ограничении на риск', 'Стратегия максимизации доходности при ограничении на риск','Стратегия максимизации доходности при ограничении на риск']
  return (
      <div className={style.HeaderModule}>
        <div className={style.HeaderModule_inneer}>
          <div className={style.HeaderModule_logo}>Клиентская Панель</div>
          <div id='HeaderModule_info' className={style.HeaderModule_info}>
            <img onClick={openClouseRightPanel} className={style.HeaderModule_infos} src={info} alt="" />
            <img onClick={openClouseRightPanel} className={style.HeaderModule_exit} src={img} alt="" />
            <Combobox2 onChenge={combobboxChenge} id={'combobox'} data={state2} title={'Протукт(торговая стратегия)'}/>
            <ItemInfo title={'Средства клиента'} data={state2[state].balance}/>
            <ItemInfo title={'Приращение за период'} data={state2[state].annualGrowth}/>
            <ItemInfo title={'Приращение за день'} data={state2[state].annualToday}/>
          </div>
        </div>
      </div>
  );
}

export default HeaderModule;
