import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import style from './css/barGrafic.module.css'

function BarGrafic({data, name, number}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins:{
      legend:{
        labels:{
          color: '#fff'
        }
      }
    },
    scales:{
        y:{
          ticks: {
            color: '#fff',
          },
          beginAtZero:true,
          max: number == 1? null:0,
        },
        x:{
          ticks: {
            color: '#fff',
          }
        }
      },
  };

  const data2 = {
    labels: data.map(row => row.year),
    datasets: [
            {
              label: name?name:'',
              data: data.map(row => row.count),
              backgroundColor: '#00ADB5',
            }
          ]
  };


  useEffect(() => {
  }, [data[0]])

  return (
    <div >
      <Bar className={style.lineGrafic} options={options} data={data2}/>
    </div>
  );
}

export default BarGrafic;