import HeaderModule from "../../../module/headerModule/headerModule";
import Titlepage from "../../../UI/Title/title";
import LeftPanelModule from "../../../module/leftPanelModule/leftPanelModule";
import PaymentBarModule from "../../../module/paymentBarModule/paymentBarModule";

function Pay() {

  return (
    <div className="Pay">
     <HeaderModule/>
     <div className="flex-main">
      <LeftPanelModule id={'link_7'}/>
      <div id="mainRightPanel" className="main-right-panel">
        <Titlepage name={'Вывод/ввод средств на/со счёта'}/>
        <PaymentBarModule/>
      </div>
     </div>
    </div>
  );
}

export default Pay;