import style from './css/button.module.css'

function Button({name, onClick}) {
  return (
    <div onClick={onClick} className={style.button}>
      {name}
    </div>
  );
}

export default Button;