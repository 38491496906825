import style from './css/homeBarModule.module.css'
import Button from '../../UI/button/button'
import TitleBlock from '../../UI/titleBlock/titleBlock';
import Combobox from '../../UI/combobox/combobox';
import TextArea from '../../UI/textarea/textarea';
import InfoBlock from '../../UI/infoBlock/infoBlock';
import LineGrafic from '../../UI/lineGrafic/lineGrafic';
import BarGrafic from '../../UI/barGrafic/barGrafic';
import RadioBox from '../../UI/checkbox copy/RadioBox';
import Input from '../../UI/input/input';
import { useEffect, useRef, useState } from 'react';
import { createPDF } from '../../function/pdfCreate/pdfCreate';
import pdfMake from 'pdfmake/build/pdfmake'
import ErrorPanel from '../errorPanel/errorPanel'
import pdfFonts from 'pdfmake/build//vfs_fonts'
import { useScreenshot } from 'use-react-screenshot';
import Tooltip from '../../UI/tooltip/tooltip';
import LoaderModule from '../loaderModule/loaderModule';
import axios from 'axios';

let file = []

function HomeModule() {  
  const data = [];
  let timeAll = new Date().getTime()

  for (let index = 0; index < 30; index++) {
    data.push({year: new Date(timeAll).toLocaleDateString(), count: 0 })
  }

  let email = localStorage.getItem('email')

  const [state, setState] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [state2, setState2] = useState(null)
  const [state4, setState4] = useState(null)
  const [state5, setState5] = useState(null)

  useEffect(()=>{
    axios.get(`http://5.188.159.168:8989/api/scores?email=${email}`)
    .catch((error) => console.log(error))
    .then(res => {
      setState2(res.data);
    })
  },[])

  
  const ref = useRef(null)
  const [image, takeScreenshot] = useScreenshot({
    type:'image/png',
    quality: 1.0 ,
    willReadFrequently: true
  })
  
  useEffect(()=>{
    if (document.getElementById("radio") !== null) {
      let radio = document.getElementById('radio')
      radio.checked = true
    }
  },[state2])

  useEffect(()=>{
    if (state4 !== null) {
      if (state === true) {
        let chart = []
        for (let index = 0; index < state4[0].chart.data.length; index++) {
          chart.push({year: new Date(state4[0].chart.date[index]*1000).toLocaleDateString(), count:state4[0].chart.data[index]})
        }
        setState5({
          one: state4[0].annualPeriod,
          two: state4[0].annualGrowth,
          chart:chart
        })
      } else {
        let chart = []
        for (let index = 0; index < state4[1].chart.data.length; index++) {
          chart.push({year: new Date(state4[1].chart.date[index]*1000).toLocaleDateString(), count:state4[1].chart.data[index]})
        }
        setState5({
          one: state4[1].MDDPeriod,
          two: state4[1].lossesYear,
          chart:chart
        })
      }
    }
  },[state4])


  const radioChange = () => {
    setState(!state)
    if (state4 !== null) {
      if (state !== true) {
        let chart = []
          for (let index = 0; index < state4[0].chart.data.length; index++) {
            chart.push({year: new Date(state4[0].chart.date[index]*1000).toLocaleDateString(), count:state4[0].chart.data[index]})
          }
          setState5({
            one: state4[0].annualPeriod,
            two: state4[0].annualGrowth,
            chart:chart
          })
        } else {
          let chart = []
          for (let index = 0; index < state4[1].chart.data.length; index++) {
            chart.push({year: new Date(state4[1].chart.date[index]*1000).toLocaleDateString(), count:state4[1].chart.data[index]})
          }
          setState5({
            one: state4[1].MDDPeriod,
            two: state4[1].lossesYear,
            chart:chart
          })
        }
      }
  }

  const createPDFFile = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(createPDF(file)).download('name.pdf');
  }

  const getMasiv = (image) => {
    let fiel = []
    file.map(item=>{
      fiel.push(item)
    })
    fiel.push({text: document.getElementById('textarea').value, fontSize: 16,})
    fiel.push({image:image, width: 400})
    file = fiel
  }

  const getimg = () => takeScreenshot(ref.current).then(getMasiv)

  const startAnalis = () => {
    let timeTo = Date.parse(document.getElementById('timeTo').value)
    let timeFrom = Date.parse(document.getElementById('timeFrom').value)
    let options = document.getElementById('seledes')
    let id = options.selectedIndex + 1
    if (timeTo|| 0 !== 0 && timeFrom|| 0 !== 0) {
      axios.get(`http://5.188.159.168:8989/api/dataScores?timeFrom=${timeFrom/1000}&timeTo=${timeTo/1000}&id=${id}&email=${email}`)
      .catch((error) => console.log(error))
      .then(res => {
        setState4(res.data);
      })
    } else{
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000);
    }
  }

  return (
    <div style={{backgroundColor: '#222831'}} ref={ref} >
      {state2? 
      <div className={style.HomeModule}>
        <TitleBlock name={'Настройка параметров анализа'}/>
        <div className={style.HomeModule_filterBlock + ' ' + style.HomeModule_Block}>
          <div className={style.HomeModule_inner}>
              <div className={style.HomeModule_filters}>
                <Combobox id={'seledes'} list={state2} name={'Выбрать стратегию'}/>
                <div className={style.HomeModule_date}>
                  <Input id={'timeFrom'} name={'Выбрать начальную дату  '} type={'date'}/>
                  <Input id={'timeTo'} name={'Выбрать конечную дату  '} type={'date'}/>
                </div>
              </div>
              <Button onClick={startAnalis} name={'Начать анализ'}/>
            </div>
          </div>
          <div className={style.HomeModule_infoBlocks}>
            <div className={style.HomeModule_infoBlocks_inner}>
              <TitleBlock name={'Коментирование результата'}/>
              <div className={style.HomeModule_infoBlock + ' ' + style.HomeModule_Block}>
                <div className={style.buttons_inner}>
                  <RadioBox id={'radio'} onChange={radioChange} name={'Анализ доходности'}/>
                  <RadioBox onChange={radioChange} name={'Анализ риска'}/>
                </div>
                <TextArea id={'textarea'} palceholder={'Поле для ввода пометок к результатам'}/>
                <div className={style.button_inner}>
                  <Tooltip name={'Нажав кнопку в отчёт вы начнёте наполнения файла с отчётом, после каждного нажатия в файле будет появляться новая информация в фыайле к дополнения к прошлой'}/>
                  <Button onClick={getimg} name={'В отчёт'}/>
                </div>
                <div className={style.button_inner}>
                  <Tooltip name={'Нажав кнопку сгенерировать отчёт вы окончите наполнение файлаб и файл будет создаватьс заного'}/>
                  <Button onClick={createPDFFile} name={'Сгенерировать отчёт'}/>
                </div>
              </div>
            </div>
            <div className={style.HomeModule_infoBlocks_inner}>
              <TitleBlock name={'Текущее значения показателей/визуализация'}/>
              <div className={style.HomeModule_infoBlock + ' ' + style.HomeModule_Block + ' ' + style.HomeModule_infoBlock_grafics}>
                <div className={style.buttons_inner}>
                  <InfoBlock name={'Абсолютное значение'} data={state5?state5.one.toFixed(2):'0'}/>
                  <InfoBlock name={'Относительное приращение'} data={state5?state5.two.toFixed(2):'0'}/>
                </div>
                {state?<LineGrafic name={'Доходность'} data={state5?state5.chart: data} />:<BarGrafic name={'Риск'} data={state5?state5.chart: data} />}
              </div>
            </div>
          </div>
        </div>:
        <LoaderModule/>
      }
      {errorState? 
        <ErrorPanel text={'Задан некорректный интервал времени'}/>:
        <div className=""></div>
      }
    </div>
  );
}

export default HomeModule;