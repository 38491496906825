import style from './css/input.module.css'

function Input({id,palceholder, type, name}) {
  return (
    <div className={style.input_bloc}>
      <div className={style.input_blocTitle}>{name}</div>
      <input id={id} className={style.input} type={type}  placeholder={palceholder}/>
    </div>
  );
}

export default Input;