import style from './css/statiscsBarModule.module.css'
import Input from '../../UI/input/input'
import Button from '../../UI/button/button'
import TitleBlock from '../../UI/titleBlock/titleBlock';
import Combobox from '../../UI/combobox/combobox';
import TextArea from '../../UI/textarea/textarea';
import { createPDF } from '../../function/pdfCreate/pdfCreate';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build//vfs_fonts'
import LineGrafic from '../../UI/lineGrafic/lineGrafic';
import ErrorPanel from '../errorPanel/errorPanel'
import Tooltip from '../../UI/tooltip/tooltip';
import { useEffect, useState, useRef } from 'react';
import { useScreenshot } from 'use-react-screenshot';
import axios from 'axios';
import LoaderModule from '../loaderModule/loaderModule';
import BarGrafic from '../../UI/barGrafic/barGrafic';

let file = []

function StatiscsBarModule() {  
  const data = [];
  let timeAll = new Date().getTime()

  for (let index = 0; index < 30; index++) {
    data.push({year: new Date(timeAll).toLocaleDateString(), count: 0 })
  }
  
  let list = [{name:''}]

  let email = localStorage.getItem('email')

  const [state2, setState2] = useState(null)
  const [state4, setState4] = useState(list)
  const [errorState, setErrorState] = useState(false)
  const [state3, setState3] = useState(null)
  const [state5, setState5] = useState(null)
  const [state6, setState6] = useState(null)
  const [state7, setState7] = useState(null)
  const [state8, setState8] = useState(0)
  
  useEffect(()=>{
    axios.get(`http://5.188.159.168:8989/api/scores?email=${email}`)
    .catch((error) => console.log(error))
    .then(res => {
      setState2(res.data);
    })
  },[])

  
  const ref = useRef(null)
  const [image, takeScreenshot] = useScreenshot({
    type:'image/png',
    quality: 1.0
  })

  const createPDFFile = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(createPDF(file)).download('name.pdf');
  }

  const getMasiv = (image) => {
    let fiel = []
    file.map(item=>{
      fiel.push(item)
    })
    fiel.push({text: document.getElementById('textarea').value, fontSize: 16,})
    fiel.push({image:image, width: 400})
    file = fiel
  }

  const getimg = () => takeScreenshot(ref.current).then(getMasiv)

  useEffect(()=>{
    if (state4.length !== list.length) {
      let chart = []
      let element = []
      for (let index = 0; index < state4.length; index++) {
        for (let index2 = 0; index2 < state4[index].chart.data.length; index2++) {
          element.push({year: new Date(state4[index].chart.date[index2]*1000).toLocaleDateString(), count: state4[index].chart.data[index2]})
        }
        chart.push(element)
        element = []
      }
      let options = document.getElementById('seledes2')
      let list = options.value.split(" ")
      if (list[0] == 'количество') {
        setState8(1)
        setState5(chart[0])
      } else {
        setState8(0)
        setState5(chart[0])
      }
      setState3(chart)
      let chart2 = []
      let element2 = []
      for (let index = 0; index < state4.length; index++) {
        for (let index2 = 0; index2 < state4[index].chart2.data.length; index2++) {
          element2.push({year: new Date(state4[index].chart2.date[index2]*1000).toLocaleDateString(), count: state4[index].chart2.data[index2]})
        }
        chart2.push(element2)
        element2 = []
      }
      setState6(chart2[0])
      setState7(chart2)
    }
  },[state4])

  const startAnalis = () => {
    let timeTo = Date.parse(document.getElementById('timeTo').value)
    let timeFrom = Date.parse(document.getElementById('timeFrom').value)
    let options = document.getElementById('seledes')
    let id = options.selectedIndex + 1
    if (timeTo|| 0 !== 0 && timeFrom|| 0 !== 0) {
      axios.get(`http://5.188.159.168:8989/api/dataOperations?timeFrom=${timeFrom/1000}&timeTo=${timeTo/1000}&id=${id}&email=${email}`)
      .catch((error) => console.log(error))
      .then(res => {
        setState4(res.data)
      })
    } else{
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000);
    }
  }

  const specChange = () => {
    let options = document.getElementById('seledes2')
    let id = options.selectedIndex 
    let list = options.value.split(" ")
    if (list[0] == 'количество') {
      setState8(1)
    } else {
      setState8(0)
    }
    setState5(state3[id])
    setState6(state7[id])
  }
  
  return (
    <div style={{backgroundColor: '#222831'}} ref={ref}>
      {state2? 
      <div className={style.statiscsBarModule}>
        <TitleBlock name={'Настройка параметров анализа'}/>
        <div className={style.statiscsBarModule_filterBlock + ' ' + style.statiscsBarModule_Block}>
          <div className={style.statiscsBarModule_inner}>
            <div className={style.statiscsBarModule_filters}>
              <Combobox id={'seledes'} list={state2} name={'Выбрать стратегию'}/>
              <div className={style.statiscsBarModule_date}>
                <Input id={'timeFrom'} name={'Выбрать начальную дату  '} type={'date'}/>
                <Input id={'timeTo'} name={'Выбрать конечную дату  '} type={'date'}/>
              </div>
            </div>
            <Button onClick={startAnalis} name={'Начать анализ'}/>
          </div>
        </div>
        <div className={style.statiscsBarModule_infoBlocks}>
          <div className={style.statiscsBarModule_infoBlocks_inner}>
            <TitleBlock name={'Коментирование/Сохранение результата'}/>
            <div className={style.statiscsBarModule_infoBlock + ' ' + style.statiscsBarModule_Block}>
              <Combobox onChange={specChange} id={'seledes2'} list={state4} name={'Выбор визуализируемых показателей счёта'}/>
              <TextArea id={'textarea'} palceholder={'Поле для ввода пометок к результатам'}/>
              <div className={style.button_inner}>
                <Tooltip name={'Нажав кнопку в отчёт вы начнёте наполнения файла с отчётом, после каждного нажатия в файле будет появляться новая информация в файле к дополнения к прошлой'}/>
                <Button onClick={getimg} name={'В отчёт'}/>
              </div>
              <div className={style.button_inner}>
                <Tooltip name={'Нажав кнопку сгенерировать отчёт вы окончите наполнение файлаб и файл будет создаватьс заного'}/>
                <Button onClick={createPDFFile} name={'Сгенерировать отчёт'}/>
              </div>
            </div>
          </div>
          <div className={style.statiscsBarModule_infoBlocks_inner}>
            <TitleBlock name={'Визуализация результата'}/>
            <div className={style.statiscsBarModule_infoBlock + ' ' + style.statiscsBarModule_Block + ' ' + style.statiscsBarModule_infoBlock_grafics}>
              {state8 == 0?<LineGrafic name={state5?state5.length > 4?`${state5.length} заключённых сделок`: state5.length > 1?`${state5.length} заключённая сделка`:`${state5.length} заключённые сделки`:''} data={state5?state5:data}/>: <BarGrafic name={state5?state5.length > 4?`${state5.length} заключённых сделок`: state5.length > 1?`${state5.length} заключённая сделка`:`${state5.length} заключённые сделки`:''} number={state8} data={state5?state5:data} />}
            </div>
          </div>
        </div>
      </div>:
    <LoaderModule/>
    }
    {errorState? 
      <ErrorPanel text={'Задан некорректный интервал времени'}/>:
      <div className=""></div>
    }
    </div>
  );
}

export default StatiscsBarModule;