import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  info: 0
}


export const id = createSlice({
  name: 'id',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.info = action.payload
    },
  }
})

export const { setId } = id.actions
export default id.reducer