import { Route, Routes } from "react-router-dom";
import Home from "./main/home/home";
import Login from "./login/login";
import Register from "./register/register";
import ResetPass from "./resetPass/resetPass";
import Add from "./main/add/add";
import Dinam from "./main/dinam/dinam";
import Info from "./main/info/info";
import Pay from "./main/pay/pay";
import Statistic from "./main/statistic/statistic";
import Tranz from "./main/tranz/tranz";
import CodeEmail from "./code/codeEmail";
import CodeNumber from "./code/codeNumber";

function Page() {

  return (
    <div className="Page">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/resetpass" element={<ResetPass/>}/>
        <Route path="/add" element={<Add/>}/>
        <Route path="/dynamics" element={<Dinam/>}/>
        <Route path="/information" element={<Info/>}/>
        <Route path="/payment" element={<Pay/>}/>
        <Route path="/codeEmail" element={<CodeEmail/>}/>
        <Route path="/codeNumber" element={<CodeNumber/>}/>
        <Route path="/statistics" element={<Statistic/>}/>
        <Route path="/transactions" element={<Tranz/>}/>
      </Routes>
    </div>
  );
}

export default Page;