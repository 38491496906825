import RegisterModule from "../../module/registerModule/registerModule";

function Register() {
  return (
    <div className="Register">
     <RegisterModule/>
    </div>
  );
}

export default Register;