import style from './css/RadioBox.module.css'

function RadioBox({name,checked,onChange, id}) {
  return (
    <div className={style.content}>
      <div className={style.title}>{name}</div>
      <label className={style.radioButton}>
        <input id={id} onChange={onChange} checked={checked} type="radio" name="option" value="option1"/>
        <div className={style.radioCircle}></div>
      </label>
    </div>
  );
}

export default RadioBox;