import style from './css/itemInfo.module.css'

function Combobox2({title, data, onChenge,id}) {  
  return (
    <div className={style.combobox_inner}>
      <div className={style.combobox_title}>{title}</div>
      <select onChange={onChenge} id={id} className={style.combobox}>
        {data.map((item) => 
          <option key={data.indexOf(item)}>{item.name}</option>
        )}
      </select>
    </div>
  );
}

export default Combobox2;