import style from './css/loaderModule.module.css'

function LoaderModule() {  

  return (
    <div className={style.LoaderModule}>
      <div className={style.LoaderModule_inner}>
        <div className={style.loader}>
          <div className={style.LoaderModule_square + ' ' + style.sq1}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq2}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq3}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq4}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq5}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq6}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq7}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq8}></div>
          <div className={style.LoaderModule_square + ' ' +  style.sq9}></div>
        </div>
      </div>
    </div>
  );
}

export default LoaderModule;