import Titlepage from "../../../UI/Title/title";
import HeaderModule from "../../../module/headerModule/headerModule";
import HomeModule from "../../../module/homeBarModule/homeBarModule";
import LeftPanelModule from "../../../module/leftPanelModule/leftPanelModule";

function Home() {

  return (
    <div className="Home">
     <HeaderModule/>
     <div className="flex-main">
      <LeftPanelModule id={'link_1'}/>
      <div id="mainRightPanel" className="main-right-panel">
        <Titlepage name={'Данные клиентского счёта'}/>
        <HomeModule/>
      </div>
     </div>
    </div>
  );
}

export default Home;