import CodeModule from "../../module/codeModule/codeModule";

function CodeEmail() {
  return (
    <div className="CodeEmail">
     <CodeModule url={'/codeNumber'} title="Подтверждение почты" desc="На вашу почту, для подтверждения личности отправлено сообщение с кодом"/>
    </div>
  );
}

export default CodeEmail;