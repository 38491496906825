import LoginModul from "../../module/loginModule/loginModule";

function Login() {
  return (
    <div className="Login">
     <LoginModul/>
    </div>
  );
}

export default Login;