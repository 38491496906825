
export function createPDF(file) {  
  var docInfo = {
	
    info: {
      title:'Тестовый документ PDF',
      author:'Viktor',
      subject:'Theme',
      keywords:'Ключевые слова'
    },
    
    pageSize:'A4',
    pageOrientation:'portrait',//'portrait'
    pageMargins:[50,50,30,60],
    
    header:[{
      text:''
    }],
    
    footer:function(currentPage,pageCount) {
      return {
        text: currentPage.toString() + 'из' + pageCount,
        alignment:'center',
        margin:[0,30,10,50]
      }
    },

    content:file
  }
  return docInfo
}