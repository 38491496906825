import HeaderModule from "../../../module/headerModule/headerModule";
import Titlepage from "../../../UI/Title/title";
import LeftPanelModule from "../../../module/leftPanelModule/leftPanelModule";
import InformationBarModule from "../../../module/informationBarModule/informationBarModule";

function Info() {

  return (
    <div className="Info">
     <HeaderModule/>
     <div className="flex-main">
      <LeftPanelModule id={'link_5'}/>
      <div id="mainRightPanel" className="main-right-panel">
        <Titlepage name={'Информирование о продуктах'}/>
        <InformationBarModule/>
      </div>
     </div>
    </div>
  );
}

export default Info;