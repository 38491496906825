import CodeModule from "../../module/codeModule/codeModule";

function CodeNumber() {
  return (
    <div className="CodeNumber">
     <CodeModule url={'/'} title="Подтверждение номера телефона" desc="На ваш номер телефона, для подтверждения личности отправлено сообщение с кодом"/>
    </div>
  );
}

export default CodeNumber;