import style from './css/informationBarModule.module.css'
import Button from '../../UI/button/button'
import TitleBlock from '../../UI/titleBlock/titleBlock';
import Combobox from '../../UI/combobox/combobox';
import TextArea from '../../UI/textarea/textarea';
import InfoBlock from '../../UI/infoBlock/infoBlock';
import LineGrafic from '../../UI/lineGrafic/lineGrafic';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build//vfs_fonts'
import Tooltip from '../../UI/tooltip/tooltip';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useScreenshot } from 'use-react-screenshot';
import { createPDF } from '../../function/pdfCreate/pdfCreate';
import LoaderModule from '../loaderModule/loaderModule';

let file = []

function InformationBarModule() {  
  const data = [];
  let timeAll = new Date().getTime()

  for (let index = 0; index < 30; index++) {
    data.push({year: new Date(timeAll).toLocaleDateString(), count: 0 })
  }

  
  let list = [{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''},{name:''}]

  let email = localStorage.getItem('email')

  const [state2, setState2] = useState(null)
  const [state4, setState4] = useState(list)
  const [state6, setState6] = useState(list)
  const [state3, setState3] = useState(null)
  const [state5, setState5] = useState(null)
  const [state7, setState7] = useState(null)
  
  useEffect(()=>{
    axios.get(`http://5.188.159.168:8989/api/scores?email=${email}`)
    .catch((error) => console.log(error))
    .then(res => {
      setState2(res.data);
    })
  },[])

  
  const ref = useRef(null)
  const [image, takeScreenshot] = useScreenshot({
    type:'image/png',
    quality: 1.0
  })

  const createPDFFile = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(createPDF(file)).download('name.pdf');
  }

  const getMasiv = (image) => {
    let fiel = []
    file.map(item=>{
      fiel.push(item)
    })
    fiel.push({text: document.getElementById('textarea').value, fontSize: 16,})
    fiel.push({image:image, width: 400})
    file = fiel
  }

  const getimg = () => takeScreenshot(ref.current).then(getMasiv)

  useEffect(()=>{
    if (state6.length !== list.length) {
      let chart = []
      let element = []
      for (let index = 0; index < state6.length; index++) {
        for (let index2 = 0; index2 < state6[index].chart.data.length; index2++) {
          element.push({year: new Date(state6[index].chart.date[index2]*1000).toLocaleDateString(), count: state6[index].chart.data[index2]})
        }
        chart.push(element)
        element = []
      }
      setState5(chart)
      setState3(chart[0])
    }
  },[state6])

  const startAnalis = () => {
    let options = document.getElementById('seledes')
    let id = options.selectedIndex
    axios.get(`http://5.188.159.168:8989/api/dataInfomation?id=${id}&email=${email}`)
    .catch((error) => console.log(error))
    .then(res => {
      setState4(res.data.charts)
      setState6(res.data.charts[0].table)
      setState7(res.data)
    })
  }

  const specChange = () => {
    let options = document.getElementById('seledes2')
    let id = options.selectedIndex 
    setState6(state4[id].table)
  }

  const specChange2 = () => {
    let options = document.getElementById('seledes3')
    let id = options.selectedIndex 
    setState3(state5[id])
  }
  
  return (
    <div style={{backgroundColor: '#222831'}} ref={ref}>
      {state2? 
        <div className={style.informationBarModule}>
          <TitleBlock name={'Настройка параметров анализа'}/>
          <div className={style.informationBarModule_filterBlock + ' ' + style.informationBarModule_Block}>
            <div className={style.informationBarModule_inner}>
              <div className={style.informationBarModule_filters}>
                <Combobox id={'seledes'} list={state2} name={'Выбрать стратегию'}/>
              </div>
              <Button onClick={startAnalis} name={'Начать анализ'}/>
            </div>
          </div>
          <div className={style.informationBarModule_infoBlocks}>
            <div className={style.informationBarModule_infoBlocks_inner}>
              <TitleBlock name={'Коментирование/Сохранение результата'}/>
              <div className={style.informationBarModule_infoBlock + ' ' + style.informationBarModule_Block}>
                <div className={style.infos_inner}>
                  <InfoBlock name={'Доходность'} data={state7?state7.profitability:'0'}/>
                  <InfoBlock name={'Риск'} data={state7?state7.risk:'0'}/>
                </div>
                <Combobox onChange={specChange} id={'seledes2'} list={state4} name={'Выбор группы визуализируемых показателей'}/>
                <Combobox onChange={specChange2} id={'seledes3'} list={state6} name={'Выбор визуализируемого показателя'}/>
                <TextArea value={state7?state7.text:''} palceholder={'Поле описания выбраного продукта(торговой стратегии)'}/>
                <TextArea id={'textarea'} palceholder={'Поле для ввода пометок к результатам'}/>
                <div className={style.button_inner}>
                  <Tooltip name={'Нажав кнопку в отчёт вы начнёте наполнения файла с отчётом, после каждного нажатия в файле будет появляться новая информация в файле к дополнения к прошлой'}/>
                  <Button onClick={getimg} name={'В отчёт'}/>
                </div>
                <div className={style.button_inner}>
                  <Tooltip name={'Нажав кнопку сгенерировать отчёт вы окончите наполнение файлаб и файл будет создаватьс заного'}/>
                  <Button  onClick={createPDFFile} name={'Сгенерировать отчёт'}/>
                </div>
              </div>
            </div>
            <div className={style.informationBarModule_infoBlocks_inner}>
              <TitleBlock name={'Визуализация результата'}/>
              <div className={style.informationBarModule_infoBlock + ' ' + style.informationBarModule_Block + ' ' + style.informationBarModule_infoBlock_grafics}>
                <LineGrafic data={state3?state3:data}/>
              </div>
            </div>
          </div>
        </div>:
    <LoaderModule/>
    }
    </div>
  );
}

export default InformationBarModule;