import style from './css/OpenClouseButton.module.css'
import arrow from '../../image/arrow-prev-small-svgrepo-com.svg'

function OpenClouseButton({onClick}) {
  return (
    <div id='buttonLeftPanel' onClick={onClick} className={style.OpenClouseButton}>
      <img src={arrow} className={style.OpenClouseButton_arrow} alt="" />
    </div>
  );
}

export default OpenClouseButton;