import { Link, useNavigate } from 'react-router-dom';
import Button from '../../UI/button/button'
import Input from '../../UI/input/input'
import style from './css/codeModule.module.css'
import Title from './components/UI/Title/title';
import { useEffect } from 'react';

function CodeModule({title, desc,url}) {
  const route = useNavigate()

  const login = () =>{
    if (document.getElementById('inputcode').value === '123456') {
      if (title === 'Подтверждение номера телефона') {
        localStorage.setItem('login', 'admin')
      }
      route(url)
    }
  }

  const redirection = () =>{
    if (localStorage.getItem('login') === 'admin'){
      route('/')
    }
  }

  useEffect(()=>{ 
    redirection()
  },[])

  return (
    <div className={style.CodeModule}>
      <div className={style.CodeModule_block}>
        <div className={style.CodeModule_inner}>
          <Title name={title}/>
          <div className={style.code_text}>{desc}</div>
          <Input id={'inputcode'} name={'Код'} type={'text'}/>
          <br/>
          <div className={style.CodeModule_link_block}>
            <Link to={'/login'} className={style.CodeModule_link} >Отмена</Link>
          </div>
          <Button onClick={login} name={'Войти'}/>
        </div>
      </div>
    </div>
  );
}

export default CodeModule;