import { useNavigate } from 'react-router-dom';
import Links from './components/link/link';
import style from './css/leftPanelModule.module.css'
import styleButton from './components/OpenClouseButtin/css/OpenClouseButton.module.css'
import stylelink from './components/link/css/link.module.css'
import { useEffect } from 'react';
import OpenClouseButton from './components/OpenClouseButtin/OpenClouseButton';

function LeftPanelModule({id}) {  
  const route = useNavigate()

  const logout = async() => {
    localStorage.clear();
    route('/login')
  }
  useEffect(()=>{
    let list = document.getElementsByClassName(stylelink.Link)
    for (let index = 0; index < list.length; index++) list[index].className = stylelink.Link
    document.getElementById(id).classList.add(stylelink.link_active)
  },[])

  const openClousePanel = () => {
    let block = document.getElementById('leftPanel')
    let button = document.getElementById('buttonLeftPanel')
    let main = document.getElementById('mainRightPanel')
    if (block.classList.contains(style.leftPanelModule_active)) {
      block.classList.remove(style.leftPanelModule_active)
      button.classList.add(styleButton.OpenClouseButton_active)
      main.style.marginLeft = '50px'
    } else {
      button.classList.remove(styleButton.OpenClouseButton_active)
      block.classList.add(style.leftPanelModule_active)
      main.style.marginLeft = '400px'
    }
  }

  return (
    <div id='leftPanel' className={style.leftPanelModule + ' ' + style.leftPanelModule_active}>
      <div className={style.leftPanelModule_inner}>
        <div className={style.leftPanelModule_topLink}>
          <Links link={'/'} id={'link_1'} name={'Данные клиентского счёта'}/>
          <Links link={'/statistics'} id={'link_2'} name={'Статистика операционных показателей'}/>
          <Links link={'/dynamics'} id={'link_3'} name={'Отчётность по динамике счёта'}/>
          <Links link={'/transactions'} id={'link_4'} name={'Отчётность по торговым транзакциям'}/>
          <Links link={'/information'} id={'link_5'} name={'Информирование о продуктах'}/>
          <Links link={'/add'} id={'link_6'} name={'Замена/добавление продукта'}/>
          <Links link={'/payment'} id={'link_7'} name={'Вывод/ввод средств на/со счёта'}/>
        </div>
        <OpenClouseButton onClick={openClousePanel}/>
        <div onClick={logout} className={style.leftPanelModule_logout}>Выйти из кабинета</div>
      </div>
    </div>
  );
}

export default LeftPanelModule;
