import style from './css/tooltip.module.css'

function Tooltip({name}) {

    return (
      <div className={style.tooltip}>
        <div className={style.icon}>i</div>
        <div className={style.tooltiptext}>{name}</div>
      </div>
    );
  }
  
  export default Tooltip;