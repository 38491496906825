import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import style from './css/lineGrafic.module.css'

function LineGrafic({data, name}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins:{
      legend:{
        labels:{
          color: '#fff'
        }
      }
    },
    scales:{
        y:{
          ticks: {
            color: '#fff',
          },
          beginAtZero:true,
          min: 0,
        },
        x:{
          ticks: {
            color: '#fff',
          }
        }
      },
  };

  const data2 = {
      labels: data.map(row => row.year),
      datasets: [
              {
                label: name?name:'',
                data: data.map(row => row.count),
                borderColor: data[11].count === 0 ?'#393E46':'#00ADB5',
                backgroundColor: data[11].count === 0 ?'#393E46':'#00ADB5',
                pointRadius: 0.02,
              }
            ]
    };

  useEffect(() => {
  }, [data[0]])

  return (
    <div >
      <Line className={style.lineGrafic} options={options} data={data2}/>
    </div>
  );
}

export default LineGrafic;