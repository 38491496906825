import Button from '../../UI/button/button'
import Input from '../../UI/input/input'
import style from './css/registerModule.module.css'
import Title from './components/UI/Title/title';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import ErrorPanel from '../errorPanel/errorPanel';

function RegisterModule() {

  const [errorState, setErrorState] = useState(false)
  const [text, setText] = useState('Не введен логин или пароль')
  const route = useNavigate()

  const Gologin = () =>{
    let firstName = document.getElementById('firstName')
    let lastName = document.getElementById('lastName')
    let email = document.getElementById('email')
    let number = document.getElementById('number')
    let login = document.getElementById('login')
    let pass = document.getElementById('pass')
    let pass2 = document.getElementById('pass2')

    if (login.value !== '' && pass.value !== '' && firstName.value !== '' && lastName.value !== '' && email.value !== '' && number.value !== '' && pass2.value === pass.value) {
      axios.post('http://5.188.159.168:8904/api/register', {
        firstname: firstName.value,
        lastname: lastName.value,
        number: number.value,
        email: email.value,
        login: login.value,
        pass: pass.value,
        recover_password: pass2.value,
      })
      .catch((error) => console.log(error))
      .then(res => {
        if (res.data !== 400) {
          route('/codeEmail')
          localStorage.setItem('email', email.value)
        } else {
          setText('Аккаунт с такой почтой уже существует')
          setErrorState(true)
          setTimeout(() => {
            setErrorState(false)
          }, 5000);
        }
      })
    } else {
      setText('Не все данные введены')
      setErrorState(true)
      setTimeout(() => {
        setErrorState(false)
      }, 5000);
    }
  }


  return (
    <div className={style.registerModul}>
      <div className={style.registerModul_block}>
        <div className={style.registerModul_inner}>
          <Title name={'Регистрация'}/>
          <Input id={'firstName'} name={'Фамилия'} type={'text'}/>
          <Input id={'lastName'} name={'Имя'} type={'text'}/>
          <Input id={'email'} name={'Почта'} type={'text'}/>
          <Input id={'number'} name={'Номер телефона'} type={'text'}/>
          <Input id={'login'} name={'Логин'} type={'text'}/>
          <Input id={'pass'} name={'Пароль'} type={'password'}/>
          <Input id={'pass2'} name={'Повторите пароль'} type={'password'}/>
          <br/>
          <Link to={'/login'} className={style.registerModul_link} >У меня уже есть аккаунт</Link>
          <Button onClick={Gologin} name={'Зарегестрироваться'}/>
        </div>
      </div>
      {errorState? 
      <ErrorPanel text={text}/>:
      <div className=""></div>
      }
    </div>
  );
}

export default RegisterModule;