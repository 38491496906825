import style from './css/itemInfo.module.css'

function ItemInfo({title, data}) {  
  return (
    <div className={style.ItemInfo}>
      <div className={style.ItemInfo_title}>{title}</div>
      <div className={style.ItemInfo_data}>{data}</div>
    </div>
  );
}

export default ItemInfo;