import style from './css/infoBlock.module.css'

function InfoBlock({data, name}) {
  return (
    <div className={style.infoBlock_bloc}>
      <div className={style.infoBlock_blocTitle}>{name}</div>
      <div className={style.infoBlock}>{data}</div>
    </div>
  );
}

export default InfoBlock;