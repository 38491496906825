import style from './css/combobox.module.css'

function Combobox({name, list, id, onChange}) {
  return (
    <div>
      <div className={style.combobox_title}>{name}</div>
      <select onChange={onChange} id={id} className={style.combobox}>
        {list.map((item) => 
          <option key={list.indexOf(item)}>{item.name}</option>
        )}
      </select>
    </div>
  );
}

export default Combobox;