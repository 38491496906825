import style from './css/titleBlock.module.css'

function TitleBlock({name}) {  
  return (
    <div className={style.titleBlcok}>
      {name}
    </div>
  );
}

export default TitleBlock;